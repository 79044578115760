import type { NextPage } from "next"
import { useEffect } from "react"
import { useUser } from "@auth0/nextjs-auth0"
import { useRouter } from "next/router"
import { LoadingView } from "../components/feedback"

const Home: NextPage = () => {
  const { user, isLoading, error } = useUser()
  const router = useRouter()
  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user) {
      router.replace("/landing")
      return
    }

    router.replace("/dashboard")
  }, [user, isLoading, error, router])

  return <LoadingView />
}

export default Home
